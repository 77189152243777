
import { defineComponent, ref, onMounted } from "vue";
import videojs from "video.js";

export default defineComponent({
  name: "VideoPlayer",
  props: ["brightcoveId"],
  setup () {
    const container = ref(null as unknown as HTMLElement);
    const video = ref(null);

    const playerConfig = ref({
      playerId: "H1069QVw",
      accountId: "55864912001",
    });

    const getHeight = (breakpoint) => {
      switch (breakpoint) {
        case "xs": return "190px";
        case "sm": return "420px";
        default: return "540px";
      }
    };

    onMounted(() => {
      const scpt = document.createElement("script");
      scpt.src = `https://players.brightcove.net/${playerConfig.value.accountId}/${playerConfig.value.playerId}_default/index.min.js`;
      container.value.appendChild(scpt);
      scpt.onload = () => {
        videojs(video.value);
        const player = videojs.getPlayers()["video-player_html5_api"];
        player.ready();
        player.on("loadedmetadata", function () {
          const captions = document.getElementsByClassName("vjs-menu-button-popup vjs-captions-button")[0];
          const list: HTMLElement = captions.getElementsByTagName("ul")[0];
          const languages: string[] = [];
          for (let i = 0; i < list.children.length; i++) {
            const child: HTMLElement = list.children[i] as HTMLElement;
            languages.push(child.innerText.slice(0, child.innerText.search(",")));
          }
          languages.sort((a, b) => a.localeCompare(b));
          const dupes: number[] = [];
          for (let i = 0; i < languages.length; i++) {
            for (let j = i + 1; j < languages.length; j++) {
              if (languages[i] === languages[j]) {
                dupes.push(j);
                i = j;
                break;
              }
            }
          }
          dupes.forEach(index => {
            list.children[index].remove();
          });
        });
      };
    });
    return {
      container,
      video,
      playerConfig,
      getHeight,
    };
  },
});
